export const LOGIN = "/auth2/login";
export const UpdateUser = "/auth2/update";
export const GetAllUser = "/auth2/get_all_users";
export const SignupUser = "/auth2/signup";
export const GetNotification = "/auth2/notifications";
export const ForgotPassword = "/auth2/forgot-password";
export const UpdatePassword = "/auth2/update_password";
export const ValidateUser = "/auth2/validate_user";
export const UserAuditList = "/auth2/user_audit_list";
export const AllAuditList = "/auth2/all_audit_list";
export const ReadNotifications = "/auth2/read-notifications";
export const GetUsersByClientId = "/auth2/users";
export const DICOMProxyToken = "/proxy2/viewer-token";
// change path to localhost:3000/viewer or localhost:3000/octviewer for local testing
export const ViewerPath = "https://viewer.nexus-md.com/viewer";
export const OctViewerPath = "https://viewer.nexus-md.com/octviewer";